import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Card from "../components/card"
import CardNodo from "../components/cardNodo"
import BlueSpan from "../components/blueSpan"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnchor } from "@fortawesome/free-solid-svg-icons"

const nodi = [
  {
    title: "Nodo margherita",
    filename: "nodi-marinari-margherita.jpg",
    link: "nodi-marinari-di-accorciamento-e-regolazione",
    description:
      "È il  più classico dei nodi d’accorciamento ha dato il nome a una serie di nodi di struttura simile non sempre finalizzati alla regolazione della lunghezza dei cavi. alcune caratteristiche principali: non si logora, si scioglie facilmente dopo l'uso e ha una buona tenuta. Il pregio principale di questo tipo di nodo è l’essere sicuro a patto che il cavo sia sottoposto a costante tensione in caso contrario si scioglie con estrema facilità.",
  },
  {
    title: "Nodo semplice con gassa",
    filename: "nodi-marinari-semplice-con-gassa.jpg",
    link: "nodi-marinari-di-accorciamento-e-regolazione",
    description:
      "È il più semplice dei nodi di accorciamento, sottoposto a trazione è difficile da sciogliere.",
  },
  {
    title: "Nodo per manette",
    filename: "nodi-marinari-per-manette.jpg",
    link: "nodi-marinari-di-accorciamento-e-regolazione",
    description:
      "È un altro nodo margherita usato dalle forze dell’ordine in mancanza di regolari manette mdi metallo.",
  },
]

const DiAccorciamento = () => (
  <Layout>
    <SEO title="Nodi Marinari di Accorciamento o Regolazione" />
    <h1 className="text-center text-blue-500 font-bold text-3xl mt-12">
      <FontAwesomeIcon
        className="text-blue-600 stroke-current text-black"
        size="lg"
        icon={faAnchor}
      />{" "}
      I nodi marinari di Accorciamento o di Regolazione{" "}
      <FontAwesomeIcon
        className="text-blue-600 stroke-current text-black"
        size="lg"
        icon={faAnchor}
      />
    </h1>
    <div className="container mx-auto">
      <div className="font-serif shadow p-5 text-justify italic text-xl mt-5">
        I <BlueSpan>nodi marinari di accorciamento o regolazione</BlueSpan> sin
        genere servono ad eliminare un imbando eccessivo, ossia accorciare un
        cavo senza necessariamente tagliarlo. Bisogna sempre ricordare che i
        nodi di accorciamento risultano efficaci e danno il loro massimo
        risultato solo quando il cavo è in tensione, mentre si sciolgono
        facilmente quando il cavo è "in bando. Un particolare impiego di nodi
        d'accorciamento consiste nell'eliminazione dall'utilizzo delle parti
        usurate o danneggiate che il cavo dovesse presentare. Esistono 3
        tipologie di nodi marinari di accorciamento tra cui:
        <br />
        {nodi.map(nodo => (
          <CardNodo
            filename={nodo.filename}
            nomeNodo={nodo.title}
            description={nodo.description}
          ></CardNodo>
        ))}
      </div>
      <Card className="mt-5"></Card>
    </div>
  </Layout>
)

export default DiAccorciamento
